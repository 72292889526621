<template>
  <div>
    <div class="list">
      <ul>
        <li>
          <span class="icon">
            <img
              src="../../../assets/image/home/logo.png"
              alt=""
            >
          </span>
          <h6 class="title">
            {{ productList.title }}
          </h6>
          <span
            v-if="productList.list.length > 5"
            class="show-more"
            @click="showMore"
          >MORE</span>
        </li>
        <li
          v-for="(item, index) in listData"
          :key="index"
          class="to-choose"
          @click="jumpToDetail(item)"
        >
          <img
            :src="item.url"
            alt=""
          >
          <p class="desc">
            {{ item.name }}
          </p>
          <span class="price">{{ item.price }}</span>
        </li>
      </ul>
      <ul class="secondList">
        <li>
          <h6 class="secondTitle">
            {{ productList.title }}
          </h6>
          <a
            href="#"
            class="link"
          >MORE</a>
        </li>
        <li
          v-for="(item, index) in listData"
          :key="index"
          class="to-choose"
          @click="jumpToDetail(item)"
        >
          <img
            :src="item.url"
            alt=""
          >
          <p class="desc">
            {{ item.desc }}
          </p>
          <span class="price">{{ item.price }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productList: {
      type: Object,
      default: () => {
        return {
          title: '标题未定义',
          list: []
        }
      }
    }
  },
  data () {
    return {
      listData: [],
      show: false
    }
  },
  watch: {
    productList: {
      handler (val) {
        if (val.list.length > 4) {
          this.listData = val.list.slice(0, 4)
        } else {
          this.listData = val.list
        }
      },
      immediate: true
    }
  },
  methods: {
    jumpToDetail (item) {
      localStorage.setItem('productDetail',JSON.stringify(item))
      this.$router.push({name:'product-detail', params:{name: item.name}})
    },
    
    showMore () {
      this.show = !this.show
      this.listData = this.show ? this.productList.list : this.productList.list.slice(0, 4)
    }
  }
}
</script>
<style lang="scss" scoped>
.list {
  width: 310px;
  font-size: 12px;
  margin: 0 30px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      position: relative;
      display: flex;
      box-sizing: border-box;
      border: 1px solid #e2e2e2;
      border-bottom: 0;
      height: 70px;
      padding: 10px;
      transition: all 300ms ease;
      img {
        height: 50px;
        width: 50px;
        transition: all 300ms ease;
      }
      .desc {
        display: flex;
        align-items: center;
        padding-left: 10px;
        text-align: justify;
        flex: 1;
        margin: 0;
      }
      .price {
        position: absolute;
        bottom: 5px;
        right: 14px;
        color: red;
      }
      &:first-child {
        min-height: 82px;
      }
      &:last-child {
        border-bottom: 1px solid #e2e2e2;
      }
    }
    .to-choose {
      cursor: pointer;
      &:hover {
        padding: 0;
        img {
          height: 68px;
          width: 68px;
        }
      }
    }
  }
  .firstList {
    max-height: 362px;
    overflow: hidden;
  }
  .secondList {
    margin-top: 10px;
    li:first-child {
      display: flex;
      min-height: 75px;
      justify-content: space-between;
    }
    .secondTitle,
    .link {
      margin: 0;
      line-height: 50px;
      font-size: 16px;
    }
    .secondTitle {
      font-weight: 400;
    }
    .link {
      text-decoration: none;
      color: #9a9ab4;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .icon {
    position: absolute;
    top: -20px;
    left: 50%;
    background-color: #fff;
    transform: translateX(-50%);
    img {
      width: 70px;
      height: 70px;
    }
  }
  .title {
    position: absolute;
    left: 50%;
    top: 35px;
    transform: translateX(-50%);
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }
  .show-more {
    position: absolute;
    right: 10px;
    top: 36px;
    font-size: 18px;
    font-weight: 400;
    color: #9a9ab4;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>